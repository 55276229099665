import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import get from 'lodash/get';
import AdContainer from '../ad/AdContainer';
import FCLink from '../fc_link/fc_link';
import ClientSideWrapper from '../client_side_wrapper/client_side_wrapper';

// TODO: move to utils
const handleMegatronImage = (img, customFields) => {
  const MEGATRON_KEY = 'megatron_image';

  const REPLACE_600 =
    'upload/w_600,ar_16:9,c_fill,g_auto:body,g_north,f_auto,q_auto:best/';
  const REPLACE_2048 =
    'upload/w_2480,ar_16:9,c_fill,g_auto:body,g_north,f_auto,q_auto:best/';

  const GIF_EXTENSION = '.gif';
  const UPLOAD_DIR = 'upload/';
  const REPLACE_WEBP = '.webp';

  const megatronCustomField = get(customFields, MEGATRON_KEY);
  const imgSrc = megatronCustomField || img.source;

  return imgSrc.includes(GIF_EXTENSION)
    ? imgSrc.replace(UPLOAD_DIR, REPLACE_600)
    : imgSrc
        .replace(UPLOAD_DIR, REPLACE_2048)
        .replace('.png', REPLACE_WEBP)
        .replace('.jpg', REPLACE_WEBP);
};

const defaultTile = {
  articleHeadline: 'A new tool for treating pulmonary embolism: big data',
  eyebrow: 'Presented By Boston scientific',
  posterImage:
    'https://images.fastcompany.net/image/upload/w_970,ar_16:9,c_fill,g_auto:body,g_north,f_auto,q_auto:best/wp-cms/uploads/2024/02/BostonA4-1.webp',
  articleLink:
    'https://www.fastcompany.com/91027916/a-new-tool-for-treating-pulmonary-embolism-big-data'
};

class Megatron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      // sponsoredCardData: null,
      activeNative: true,
      tempSponsoredCardData: null,
      wasMounted: false
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.swiperResize = this.swiperResize.bind(this);
  }

  componentWillMount() {
    const { megatronNativeTile } = this.props;
    const posts = get(megatronNativeTile, 'posts', []);
    const totalSponsorPosts = posts.length;
    const randomSponsorCard = Math.floor(Math.random() * totalSponsorPosts);

    if (get(this, 'props.config.featureFlags.isDynamicNativeTileEnabled')) {
      const tempSponsoredCardData = get(
        megatronNativeTile,
        `posts[${randomSponsorCard}]`
      );
      if (tempSponsoredCardData) {
        this.setState({
          tempSponsoredCardData
        });
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.swiperResize);
    // This will cause an extra initial render but it's necessary for the DOM to match the server
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ wasMounted: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.swiperResize);
  }

  onMouseEnter(index) {
    this.setState({ activeIndex: index });
    this.swiper.slideTo(index);
    this.swiper.autoplay.stop();
  }

  onMouseLeave() {
    this.swiper.autoplay.start();
  }

  swiperResize() {
    this.swiper.update();
  }

  render() {
    const {
      activeIndex,
      activeNative,
      tempSponsoredCardData,
      wasMounted
    } = this.state;
    const { articles, config, loading } = this.props;
    const settings = {
      effect: 'fade',
      autoplay: {
        delay: 4000
      },
      on: {
        slideChangeTransitionStart: () => {
          const { activeIndex: ai } = this.swiper;
          if (ai === 2 && activeNative) {
            this.setState({
              activeNative: false
            });
          }
          this.setState({ activeIndex: ai });
        }
      }
    };

    return (
      <Fragment>
        <div className="megatron">
          <Swiper
            {...settings}
            ref={node => {
              if (node) this.swiper = node.swiper;
            }}
          >
            {articles.map(({ featured_image: img, customFields, link }) => (
              <div key={img?.source} className="megatron__slide">
                <div
                  className="megatron__slide-background"
                  style={{
                    backgroundImage: `url(${handleMegatronImage(
                      img,
                      customFields,
                      link
                    )})`
                  }}
                  loading={loading}
                />
              </div>
            ))}
          </Swiper>
          <div className="megatron__articles">
            {articles.map(
              ({ title, categories, customFields, link }, index) => (
                <article
                  key={link}
                  className={`megatron__article ${
                    activeIndex === index ? 'megatron__article--active' : ''
                  }`}
                  onMouseEnter={() => this.onMouseEnter(index)}
                  onMouseLeave={this.onMouseLeave}
                >
                  <FCLink title={title} to={link} config={config}>
                    {get(customFields, 'primary_eyebrow', false) ? (
                      <span>{get(customFields, 'primary_eyebrow', '')}</span>
                    ) : (
                      <span>{get(categories, '[0].name', '')}</span>
                    )}
                    <h2>{title}</h2>
                  </FCLink>
                </article>
              )
            )}
            {tempSponsoredCardData &&
              wasMounted && (
                <div
                  className={`megatron__article megatron__article--native ${
                    activeNative ? 'megatron__article--native__active' : ''
                  }`}
                >
                  <a
                    href={
                      tempSponsoredCardData?.articleLink ||
                      defaultTile?.articleLink
                    }
                  >
                    <div className="img-container">
                      <img
                        height="168"
                        width="300"
                        src={
                          tempSponsoredCardData?.posterImage
                            ? tempSponsoredCardData?.posterImage
                                .replace('.png', '.webp')
                                .replace('.jpg', '.webp')
                                .replace(
                                  'upload/',
                                  'upload/w_970,ar_16:9,c_fill,g_auto:body,g_north,f_auto,q_auto:best/'
                                )
                            : defaultTile?.posterImage
                        }
                        alt={
                          tempSponsoredCardData?.articleHeadline ||
                          defaultTile?.articleHeadline
                        }
                      />
                    </div>
                    <span>
                      {tempSponsoredCardData?.eyebrow || defaultTile?.eyebrow}
                    </span>
                    <h2>
                      {tempSponsoredCardData?.articleHeadline ||
                        defaultTile?.articleHeadline}
                    </h2>
                  </a>
                </div>
              )}
          </div>
        </div>

        <div className="homepage-page__ff-ad-container">
          <ClientSideWrapper showOnlyDesktop={true}>
            <AdContainer type="break" />
          </ClientSideWrapper>
        </div>
      </Fragment>
    );
  }
}

Megatron.defaultProps = {
  loading: ''
};

Megatron.propTypes = {
  config: PropTypes.shape({}).isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.string,
  megatronNativeTile: PropTypes.shape({})
};

Megatron.defaultProps = {
  megatronNativeTile: null
};

function mapStateToProps(state = {}) {
  return {
    config: state.config,
    postpage: state.postpage
  };
}

export default connect(mapStateToProps)(Megatron);
