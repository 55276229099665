/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import striptags from 'striptags';
import Card from '../card/card';
import Slug from '../slug/slug';
import FCLink from '../fc_link/fc_link';
import matchMediaDesktop from '../../../utils/client_util';
import { imageSizesLookup } from '../../../utils/cloudinary_asset_util';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import { getBrandworksName } from '../advertorial/advertorial';
import AdContainer from '../ad/AdContainer';

function noop() {}

class AllFeed extends Component {
  constructor(props) {
    super(props);
    this.renderMoreButton = this.renderMoreButton.bind(this);
    this.enableInfiniteScroll = this.enableInfiniteScroll.bind(this);
    this.handleScroll = debounce(this.handleScroll.bind(this), 1000);
  }

  componentDidMount() {
    this.isDesktop = matchMediaDesktop();
  }

  componentWillUnmount() {
    this.removeInfiniteScrollEvent();
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getAllStories() {
    const formattedStories = this.props.stories.map((post, i) => ({
      ...post,
      site: 'Fast Company',
      assetSizes:
        i === 0 ? imageSizesLookup.bigFirstMobile : imageSizesLookup[3],
      allowNoImage: true,
      excerpt: striptags(post.excerpt || '')
    }));

    if (
      this.props.allRendered ||
      this.props.paginationEnabled ||
      this.props.infiniteScrollEnabled
    ) {
      return formattedStories;
    }

    return this.props?.stories?.length >= 10
      ? formattedStories.slice(0, 10)
      : formattedStories;
  }

  getSections() {
    const sections = chunk(this.getAllStories(), 10);

    return sections.map((section, index) => ({
      id: `section-${index + 1}`,
      stories: section
    }));
  }

  addInfiniteScrollEvent() {
    window.addEventListener('scroll', this.handleScroll);
  }

  removeInfiniteScrollEvent() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  enableInfiniteScroll(e) {
    e.preventDefault();
    this.infiniteScrollInitialized = true;
    this.props.hideRecircFeed();
    this.props
      .infiniteScrollAllFeedFetch(
        {
          page: this.props.page + 1,
          slug: this.props.section,
          excludeFromHomepage: true
        },
        this.props.config
      )
      .then(() => {
        this.addInfiniteScrollEvent();
      });
  }

  handleScroll() {
    if (!this.props.infiniteScrollLoading) {
      if (this.props.totalPages < this.props.page) {
        return this.removeInfiniteScrollEvent();
      }
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 3000
      ) {
        this.removeInfiniteScrollEvent();
        return this.props
          .infiniteScrollAllFeedFetch(
            {
              page: this.props.page + 1,
              slug: this.props.section,
              excludeFromHomepage: true
            },
            this.props.config
          )
          .then(() => {
            const canFetchMorePages = this.props.totalPages > this.props.page;
            if (canFetchMorePages) this.addInfiniteScrollEvent();
          });
      }
    }
    return false;
  }

  renderMoreButton(index, allFeedLength, isLastSection, isLastPage, config) {
    if (this.props.disableMore) {
      return null;
    }
    if (this.props.infiniteScrollEnabled) {
      if (this.infiniteScrollInitialized) {
        return null;
      }

      if (
        this.props.totalPages === 1 ||
        (this.props.infiniteScrollLoading && !isLastSection)
      ) {
        return null;
      }
      if (isLastSection) {
        const page = this.props.page + 1;
        let href = '';
        switch (this.props.type) {
          case 'homepage':
            href = `/all/${page}`;
            break;
          case 'brandworks':
            href = `/fcw/${this.props.section}/${[page]}`;
            break;
          default:
            href = `/${this.props.type}/${this.props.section}/${page}`;
        }
        if (this.props.hrefOverride) {
          href = `${this.props.hrefOverride}${page}`;
        }
        return (
          <a
            title={`See More ${this.props.section} on page ${this.props.page}`}
            href={href}
            className="all-feed__more"
          >
            <button onClick={this.enableInfiniteScroll}>
              See More Stories
            </button>
          </a>
        );
      }
    }

    if (this.props.paginationEnabled) {
      if (isLastPage) {
        return null;
      }
      if (isLastSection) {
        const { page } = this.props;
        let href = '';
        switch (this.props.type) {
          case 'homepage':
            href = `/all/${page}`;
            break;
          case 'brandworks':
            href = `/fcw/${this.props.section}/${[page]}`;
            break;
          case 'fceb':
            href = `/section/fast-company-executive-board/${page}`;
            break;
          default:
            href = `/${this.props.type}/${this.props.section}/${page}`;
        }
        if (this.props.hrefOverride) {
          href = `${this.props.hrefOverride}${page}`;
        }
        if (href === '/section//2') {
          href = '/section/innovation-by-design-2019/2';
        }
        return (
          <div
            className={`all-feed__button all-feed__button--${config?.site ||
              ''} ${
              this.props.mobileSeeMoreHidden
                ? 'all-feed__button--mobile-hidden'
                : ''
            }`}
          >
            <FCLink
              title={`See More ${this.props.section} on page ${this.props.page +
                1}`}
              to={href}
              config={config}
            >
              <button>See More Stories</button>
            </FCLink>
          </div>
        );
      }
      return null;
    }

    if (index !== 1 || this.props.allRendered || this.props.disableMore) {
      return null;
    }
    return (
      <div
        className={`all-feed__button all-feed__button--${config?.site || ''} ${
          this.props.mobileSeeMoreHidden
            ? 'all-feed__button--mobile-hidden'
            : ''
        }`}
      >
        <button onClick={this.props.setAllLoadedState}>See More Stories</button>
      </div>
    );
  }

  renderMobileAd(index) {
    if (index === 1) {
      return null;
    }

    return (
      <div className="all-feed__mobile-ad-container">
        <AdContainer type="break" />
      </div>
    );
  }

  renderSlugOrName(props) {
    if (!props || !props.hasSlug) {
      return null;
    }

    if (props.authorPage) {
      if (isEmpty(props.author)) return '';

      return (
        <h2 className="author-page__all-feed-title">
          {`Stories by ${props.author?.name || ''}`}
          <span className="author-page__all-feed-title-rss">
            <a href={`/user/${props.author?.slug}/rss`}>RSS</a>
          </span>
        </h2>
      );
    }

    if (!isEmpty(props.advertorialSlugs)) {
      const advertorialName = props.advertorialSlugs?.name;
      const companyName = getBrandworksName([{ name: advertorialName }]);

      return (
        <Slug
          slugName={
            props.allStoriesTitle
              ? `ALL ${companyName} STORIES`
              : props.advertorialSlugs?.name
          }
          className="tag-page"
          advertorial={true}
        />
      );
    }

    let archives = {};
    if (props.titleOverride.indexOf('Magazine') > -1) {
      archives = {
        name: 'See Archives',
        url: '/magazine/archive'
      };
    }

    return (
      <Slug
        linkTo="//www.fastcompany.com"
        slugName={props?.titleOverride || 'All Stories'}
        archives={archives}
      />
    );
  }

  render() {
    const {
      config,
      advertorialSlugs,
      authorPage,
      author,
      hasSlug,
      titleOverride,
      isLastPage,
      type,
      allStoriesTitle,
      brandworksSlug,
      featuredTop,
      bottomLine,
      noAds
    } = this.props;
    let { modifier } = this.props;

    if (!modifier && !hasSlug) {
      modifier = 'no-slug';
    }

    const fullPageAdvertorial = modifier === 'advertorial';

    const allFeedLength = this.props.stories.length;
    let allFeedClassName = `all-feed__wrapper ${
      modifier ? `all-feed__wrapper--${modifier}` : ''
    } ${noAds ? `centered` : ''}
    `;

    if (type === 'user') {
      allFeedClassName += ` all-feed__wrapper--${type} `;
    }
    return (
      <section className={`all-feed ${!bottomLine ? 'no-bottom-line' : ''}`}>
        {this.renderSlugOrName({
          advertorialSlugs,
          authorPage,
          author,
          hasSlug,
          titleOverride,
          allStoriesTitle
        })}

        <div className={allFeedClassName}>
          {this.getSections().map((section, sectionIndex) => {
            const isLastSection =
              this.getSections().length === sectionIndex + 1;

            return (
              <div key={section.id} className="all-feed__section">
                <div className="all-feed__posts">
                  {section.stories
                    .filter(this.onlyUnique)
                    .map((story, cardIndex) => {
                      if (brandworksSlug) {
                        // eslint-disable-next-line no-param-reassign
                        story.customEyebrowText = brandworksSlug;
                        // eslint-disable-next-line no-param-reassign
                        story.fullyReplaceEyebrow = true;
                      }
                      return (
                        <Card
                          type="all-feed"
                          key={story.id || story.mediaid}
                          metadata={story}
                          config={config}
                          hasExcerpt={story.layout === 'long'}
                          fullPageAdvertorial={fullPageAdvertorial}
                          top={
                            featuredTop && sectionIndex === 0 && cardIndex === 0
                          }
                          cardEvent={() => {
                            trackGAEvent({
                              eventCategory: 'Engagement',
                              eventAction: `AllFeed:Pos-${cardIndex + 1}`,
                              eventLabel: story.link
                            });
                          }}
                        />
                      );
                    })}
                  {!noAds && this.renderMobileAd(sectionIndex)}
                  {this.renderMoreButton(
                    sectionIndex,
                    allFeedLength,
                    isLastSection,
                    isLastPage,
                    config
                  )}
                </div>

                {!noAds && (
                  <>
                    <div className="all-feed__sidebar">
                      <div
                        className="all-feed__promotion"
                        id={`sidebar-ad-${sectionIndex}`}
                      >
                        <Sticky
                          top={100}
                          bottomBoundary={`#sidebar-ad-${sectionIndex}`}
                          enabled={this.isDesktop}
                        >
                          <AdContainer type="rail_sticky" />
                        </Sticky>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

AllFeed.defaultProps = {
  stories: [],
  allRendered: false,
  modifier: '',
  mobileSeeMoreHidden: false,
  advertorialSlugs: {},
  authorPage: false,
  author: {},
  hasSlug: true,
  disableMore: false,
  titleOverride: '',
  paginationEnabled: false,
  totalPages: 0,
  page: 0,
  section: '',
  type: '',
  isLastPage: false,
  infiniteScrollEnabled: false,
  infiniteScrollAllFeedFetch: noop,
  hideRecircFeed: noop,
  setAllLoadedState: noop,
  infiniteScrollLoading: false,
  hrefOverride: '',
  mobileAdCount: [],
  allStoriesTitle: false,
  brandworksSlug: '',
  featuredTop: true,
  bottomLine: true,
  noAds: false
};

AllFeed.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.shape({})),
  allRendered: PropTypes.bool,
  setAllLoadedState: PropTypes.func,
  modifier: PropTypes.string,
  mobileSeeMoreHidden: PropTypes.bool,
  advertorialSlugs: PropTypes.shape({}),
  authorPage: PropTypes.bool,
  author: PropTypes.shape({}),
  hasSlug: PropTypes.bool,
  disableMore: PropTypes.bool,
  titleOverride: PropTypes.string,
  paginationEnabled: PropTypes.bool,
  type: PropTypes.string,
  section: PropTypes.string,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  isLastPage: PropTypes.bool,
  infiniteScrollLoading: PropTypes.bool,
  infiniteScrollEnabled: PropTypes.bool,
  infiniteScrollAllFeedFetch: PropTypes.func,
  hideRecircFeed: PropTypes.func,
  config: PropTypes.shape({}).isRequired,
  hrefOverride: PropTypes.string,
  mobileAdCount: PropTypes.arrayOf(PropTypes.number),
  allStoriesTitle: PropTypes.bool,
  brandworksSlug: PropTypes.string,
  featuredTop: PropTypes.bool,
  bottomLine: PropTypes.bool,
  noAds: PropTypes.bool
};

export default AllFeed;
